import React from 'react';
import { BiCalendar } from 'react-icons/bi';
/* import { BsEye } from 'react-icons/bs';
import { FaRegComments } from 'react-icons/fa'; */
import thumb1 from '../../assets/img/pexels/dredging.jpg';
import thumb2 from '../../assets/img/pexels/deepblue-project.png';
/* import thumb3 from '../../assets/img/blog/shell3'; */
import riverdreging from '../../assets/img/pexels/river-dredging.jpg';

import businessVenture from '../../assets/img/aboutfounder/business_venture.jpg';
import buddingEntrepreneur from '../../assets/img/aboutfounder/make_mark_via_entrepreneuship.webp';
import security from '../../assets/img/aboutfounder/security.jpeg';
import quote from '../../assets/img/aboutfounder/quote.jpg';
import drobiYt from '../../assets/img/team/drobi_yt.jpg';
import makingmark from '../../assets/img/aboutfounder/Youths-Protesting.jpg';

function SinglePost() {
    return (
        <div className="single-blog-post post-details">
            <div className="post-content">
                <div className="post-cat">
                    <a href="#jjjdjjdj">Dredging</a>
                </div>
                  {/* blog  1 */}
                  <img src={thumb1} alt="" id="qs0" />
                <h2 id="engagingdredger">From the Desk of the CEO/MD</h2>
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                   {/*  <span>
                        <BsEye
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        232 Views
                    </span>
                    <span>
                        <FaRegComments
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        35 Comments
                    </span> */}
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        28th September 2022
                    </span>
                </div>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                Dr. Okechukwu Henry Obi, a Nigerian oil magnate based abroad, has been vocal about
                the need for holistic safety in the maritime space
                Nigeria’s maritime domain is not just a rich treasure trove of minerals and resources, but
                also a major factor in trans-atlantic trading. However, it’s been burdened by maritime
                insecurity, piracy, poor dredging, inadequate berthing space for mother vessels, poor
                dredging challenges, dock problems, reduced channel width due to silting and
                unwholesome obstacles, amongst others.
                Others include inadequate functioning plants and equipment, inadequate financing and
                funding of port operations, proliferation of government agencies in port activities and
                waterways doing and duplicating the same work and charging multiple taxations and
                levies.
                </p>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                While canvassing for a seamless blue economy, Dr. Okechukwu Henry Obi, a Nigerian
                oil magnet based abroad, posited that amongst other things, including maritime safety,
                regular dredging and maintenance will open ways for business boom. This is not
                surprising because in his dual capacity as the Managing Director of Florence Petroleum
                Limited and Ecumenical Investment and Developmental Company Ltd, the safety of the
                maritime space is important to him.
                </p>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                Aside the natural and man-made challenges facing the maritime sector, he lamented
                inconsistencies in government policy and the numerous stakeholders in charge of the
                waterways. He therefore charged government to harmonise maritime operation of these
                agencies “because whatever monies we spend on them, trickles down to the final
                consumers, which then impacts negatively on the country’s GDP.”
                </p>
                <br/><br/>


                {/* blog  2 */}
                <img className="alignleft" src={thumb2} alt="" />  
                <h2  id="qs1">Deep Blue Project</h2>
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                   {/*  <span>
                        <BsEye
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        232 Views
                    </span>
                    <span>
                        <FaRegComments
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        35 Comments
                    </span> */}
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        12th August 2022
                    </span>
                </div>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                On the Deep Blue Project, he commended government’s efforts. He said: “Presently the
                government is doing well with the launch of the deep blue project which, in my honest
                opinion, is the beginning of good things to come. Right now, there has been reduced
                incidence of sea piracy attacks up to the Gulf of Guinea.
                “Government should be eager in the development of the blue economy. Nigeria being a
                country blessed with a great amount of littoral component states and waterways of great
                lengths, should strive very hard and concentrate its oil resources and revenues in
                developing a blue economy.
                </p>
                <blockquote className="wow fadeInUp" data-wow-duration="1.4s">
                “Blue Economy alone can out perform any other form of economic activities you can
                think of. The ocean is the largest and most critical ecosystem on earth. The blue
                economy is the use of sea and its resources for sustainable economic development. It
                consists of sectors like fisheries and aquaculture, marine biotechnology, renewable
                energy and shipping, hospitality (like beech side hotels and bars).
                “Majorly, it centers on trade and actions around large bodies of water. If well harnessed,
                it will solve Nigerians socio-economic problems because it will help achieve economic
                diversification, create high value jobs and ensure food security. All the ports in Nigeria
                should be developed in order to reduce tension and traffic congestion to Apapa port.”
                </blockquote>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                    Pune-based Sprng is one of India’s fastest-growing wind and solar companies, with 2.9 gigawatts in operating and contracted assets and another 7.5GW in its development pipeline.
                </p><br/><br/>

                

                  {/* blog  2 */}
                  <img className="alignleft" src={riverdreging} alt="" />  
                <h2  id="engagingdredgers">Engaging Dredgers</h2>
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                   {/*  <span>
                        <BsEye
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        232 Views
                    </span>
                    <span>
                        <FaRegComments
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        35 Comments
                    </span> */}
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        12th August 2022
                    </span>
                </div>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                On his stance about dredging the waterways, he said dredgers should be engaged in
                regular maintenance. Citing River Niger as the biggest and longest river in Nigeria, he
                said it was unfortunate it was left unattended to.
                </p>
                <blockquote>
                “He said: “The failure of successive government to dredge the river in time has
                contributed to the impassibility of the river. Dredging of waterways and channels are
                very big capital intensive projects. Though it can be divided into two, capital dredging
                and maintenance dredging, which is less capital intensive but requires regular venture.
                These are not done as it should be.”
                </blockquote>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                We have dredgers lying all over the place in Nigeria abandoned, probably procured
                with bank funds and subsidies, we should engage those dredgers to be doing
                maintenance dredging regularly, while small scale ports should be developed around
                the river banks.
                </p>

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                “There are also fears of those living in the downstream of the lower River Niger who feel
                that dredging may aggravate the erosion of the river banks and increase flooding
                incidences. These people had protested against the dredging of the River Niger in the
                past thereby stalling the work over the years.
                “Apart from this, silting of the river Niger happens more often, so maintenance dredging
                should done regularly in order to make the River Niger channel navigable to barges and
                low draft vessels. With these, I can get a low draft vessels and self propelled barges to
                lift refined products from say Waltersmith Refinery in Oguta to Onitsha port to deliver to
                coaches, from there to the North and sub-sahara region. We will create multiple jobs

                along the waterways. Food supply will be made from the North to the South via same
                partway. Nigeria is very rich but let’s fix our security.
                </p>
                <br/><br/>

                 {/* blog  3 */}
                <img className="alignleft" src={businessVenture} alt="" />
                <h3 id="businessventure">Venture into Business</h3>
            
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                    
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        4th November 2022
                    </span>
                </div>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                On how he veered from medicine to business he said: “I have always loved business
                and entrepreneurship because of my parents business backgrounds.
                </p> 
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                    As a student, I have always engaged in one business venture or the other. I knew I wasn’t going to last
                long in the medical profession because, as a young medical officer in the university of
                Calabar teaching hospital and General hospital, Saminaka, Kaduna State, I normally
                travel to Cotonou those days to buy vehicles and sell to my colleagues, friends and
                referrals in order to make some cool profit margins . So, when I met people like Alhaji
                Awalu Rano (AA Rano) in the year 2008, I knew I wasn’t going to continue in the
                medical field again because it wasn’t giving me the satisfaction and freedom that I
                desired.”
                </p>

                <p  className="wow fadeInUp" data-wow-duration="1.4s">               
                Guyana had initially aimed to stage the competitive bid round this year, but Vice President Bharrat Jagdeo said extensive preparatory work had to be completed in a fast-moving global situation.
                </p>
                <p  className="wow fadeInUp" data-wow-duration="1.4s"> 
                Jagdeo said potential bidders will be provided with terms and conditions before the auction is formally launched.
                </p><br/><br/>

                  {/* blog  3 */}
                  <img className="alignleft" src={makingmark} alt="" />
                <h3 id="makingmark">Making Mark in Nigeria</h3>
            
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                    
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        4th November 2022
                    </span>
                </div>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                Noting that “Nigeria is a virgin forest, with population of over 200 million young and
                energetic youths, making good fortune in Nigeria is kind of guaranteed though there are
                so many challenges, and as you solve some of the challenges, you make more and
                more money. The market is very huge in Nigeria.
                </p>

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                “We make good turnovers monthly and we are not even close to the market leaders. We
                do business in United Kingdom and Ghana, but you cannot compare those places with
                the kind of transactions that occurs in Nigeria in our industry.”
                </p><br/><br/>

                  {/* blog  3 */}
                  <img className="alignleft" src={buddingEntrepreneur} alt="" />
                <h3 id="buddingentrepreneurs">Advice to Budding Entrepreneurs</h3>
            
                <div className="post-meta wow fadeInUp" data-wow-duration="1.4s">
                    
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        4th November 2022
                    </span>
                </div>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                As an entrepreneur that has gone through the ropes he offered candid advice to
                budding entrepreneurs. “I will give to up- coming Nigerians that want to go into our kind
                of business or any kind of business for that matter, is to first of all look for a mentor and
                serve that person or do apprenticeship for some time and learn as much as you can
                learn from that person before venturing on your own.
                </p> 

                <p  className="wow fadeInUp" data-wow-duration="1.4s"> 
                “Our kind of business is very capital intensive, so it takes a lot of time to build up capital.So, you have to be patient and trustworthy. Integrity is the watch word and without integrity, just forget it. You will just be hitting hard rocks, before you know it, nobody will want to do business or associate with you again,” he added.           
                </p>  <br/><br/>        

                   {/* blog  3 */}
                   <img className="alignleft" src={security} alt="" />
                <h3 id="security">Charge on Security</h3>
            
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                    
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        4th November 2022
                    </span>
                </div>   

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                Charging government to make Nigeria safe and secure, Obi said: “The only incentive I
                want government to give us is to make Nigeria safe and secure for everyone.
                Government should provide enabling environment for investors and entrepreneurs like
                us to keep faith in the country.
                </p>

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                ” When I started out in 2006, I drive from Saminaka to Jos, Jos to Bauchi, Jos to
                Kaduna, Zaira, Kano, back to Abuja without fear of killer herdsmen, bandits nor
                kidnappers, but now, whenever I’m in the country, I dare not travel by road to any part of
                the country even to the Eastern part of the country. It is that bad.
                “So, we are not asking the government to give us money, we know how to make our
                money. All we need is security, peace and peaceful co-existence between the North
                and the South. My best friends are from the North. In fact, the first ever business
                support I got was N5 million in 2009 from a Muslim guy called Bashir Inuwa Ali.
                “I had just met him like a week before then. I was looking for a business loan from a
                bank and the bank was delaying, so he just asked me what I needed, I said N5 million,
                he just gave it to me without collateral nor signing any form of agreement with him.
                </p>

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                ” We are family friends now. A.A Rano also has given me credit facilities that banks
                haven’t given me. No collaterals, no sales purchase agreements signed. Just go and
                sell and bring back their capital. So, why will I not want to remain in the same country
                with these guys? Let the government make the country safe, then, we will all prosper.
                </p>

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                “The growth of commerce and industry in the present dispensation has been in reverse
                direction majorly because of insecurity. Foreign direct investment has plummeted, USD
                inflows has dried up. You don’t expect any reasonable foreign company to invest in a
                country where the security of life and property is not assured. My advice for
                improvement is to get the security of the country right, then every other thing will fall into
                place.”
                </p><br/><br/>


                     {/* blog  3 */}
                     <img className="alignleft" src={drobiYt} alt="" />
                <h3 id="qs2">About the Entrepreneur</h3>
            
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                    
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        4th November 2022
                    </span>
                </div>                  
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                Obi is an entrepreneur and the Managing Director of Florence Petroleum Limited and
                Ecumenical Investment and Developmental Company Ltd. Florence Petroleum Ltd is
                registered in Nigeria, Ghana, England and Wales and it is into bulk trading of Petroleum
                products in the West African sub-region and also into marine services and vessel
                chartering while Ecumenical Investment was incorporated in 2009 to engage in property

                developments, importation and dealers of motor vehicles, medical services, ago-allied
                and farming businesses.        
                </p>

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                He has traversed all over the country, from Calabar, Cross Rivers State where he
                graduated to the General Hospital, Saminaka, Kaduna State where he did his NYSC
                and was later retained as a medical officer until he ventured into business and relocated
                to Jos, Plateau State where he used the proceeds of the sale of his clinics to purchase
                two tanker trucks and rented a filing station where he retailed petroleum products.
                By 2009, he resigned from employment as a medical officer and concentrated fully in
                building his oil and gas, trading and real estate business after which he re-located to
                United Kingdom from where he presently operate and supervises his multi-million naira
                business enterprises with a work force of over 100 in Nigeria and abroad.
                </p><br/><br/>


                
                     {/* blog  3 */}
                     <img className="alignleft" src={quote} alt="" />
                <h3 id="quote">Quote</h3>
            
                <div className="post-meta wow fadeInUp" data-wow-duration="1.4s">
                    
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        4th November 2022
                    </span>
                </div>  

                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                    Nigeria being a country blessed with a great amount of littoral component states and
                    waterways of great lengths, should strive very hard and concentrate its oil resources
                    and revenues in developing a blue economy. Blue Economy alone can out perform any
                    other form of economic activities you can think of.         
                </p>
                <div align="right">
                    <b>
                    Dr Okechukwu Obi
                    MD Florence Petroleum
                    </b>
                </div>
            </div>
        </div>
    );
}

export default SinglePost;
