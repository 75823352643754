import { FaDraftingCompass, FaHardHat, FaPencilRuler } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/goals/vission.jpg';
import thumb2 from '../../assets/img/goals/mission.webp';
import thumb3 from '../../assets/img/goals/yellow-sticker-with-the-text-core-values-and-charts-photo.jpg';

const approchData = [
    {
        id: uuidv4(),
        thumb: thumb1,
        icon: <FaDraftingCompass />,
        heading: 'Our Vision',
        text: 'To be the best company for products and services that exceeds our client\'s expectations as and when required Our Mission: To inspire the innovator in everyone. Our Vision: The Oil and Gas Sector, by what it does directly and through others, helps build a civil society . We say YES to...',
        btnText: 'Read More',
        pageLink: '/project-details',
    },

    {
        id: uuidv4(),
        thumb: thumb2,
        icon: <FaPencilRuler />,
        heading: 'Our Mission',
        text: 'To maximize our core values and increase our stakeholder\'s worth, while rendering consistent, excellent, quality and affordable service to our teeming clients through consistency, integrity and transparency. People are our most important ...',
        btnText: 'Read More',
        pageLink: '/project-details',
    },

    {
        id: uuidv4(),
        thumb: thumb3,
        icon: <FaHardHat />,
        heading: 'Our Core Values',
        text: 'At Florence Petroleum Ltd, these are our principles: Excellence: We run a well structured organization that ensures topnotch delivery, and optimum value for every service we render.Responsibility and Integrity: At Florence Petroleum Ltd, we pride ourselves in our ...',
        btnText: 'Read More',
        pageLink: '/project-details',
    },
];

export default approchData;
