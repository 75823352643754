import React from 'react';
import bgImg from '../../assets/img/skill_bg.jpg';
import leftImg from '../../assets/img/project/blackworker11.jpg';
//import leftImg from '../../assets/img/skill_img.jpg';
import SkillBar from './SkillBar';

function SkillSet() {
    return (
        <section className="skill-wrapper section-padding pt-0">
            <div className="container">
                <div
                    className="skill-bg pt-100 pl-100 pb-100 bg-center bg-cover bg-overlay text-white"
                    style={{
                        backgroundImage: `url(${bgImg})`,
                        backgroundAttachment: 'fixed'
                    }}
                >
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-12 text-center d-none d-xl-block wow zoomInUp" data-wow-duration="1.1s">
                            <img src={leftImg} className="mlm-100" alt="" />
                        </div>
                        <div className="col-xl-6 offset-1 offset-xl-0 col-10 pr-lg-5 wow fadeInUp" data-wow-duration="1.4s">
                            <div className="block-contents">
                                <span>Our Skill set</span>
                                <h3>We deal in GASOIL, PREMIUM MOTOR SPIRIT, DUAL PURPOSE KEROSINE, LPFO, BLCO,BITUMEN</h3>
                                <h4>
                                OTHERS INCLUDE: Drilling Equipments:
Drilling Machinery, Mud Equipment and Accessories Agitators, Brakes, Cleaning units and Coiled tubing units.
                                </h4> 
                                <div align="right"><a href="#jkk">Read More</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            </div>
                            {/* PROGRESS BARS */} 
                            <SkillBar heading="Diesel" progress="90%" />
                            <SkillBar heading="Other Distillates" progress="70%" />
                            <SkillBar heading="Jet Fuel" progress="85%" />
                            <SkillBar heading="Other Products" progress="65%" />
                            <SkillBar heading="Heavy Fuel Oil(Residual)" progress="75%" />
                            <SkillBar heading="Liquified Petroleum Gases" progress="45%" />
                            <SkillBar heading="Gasoline" progress="55%" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SkillSet;
