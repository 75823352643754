import { FaCity, FaDraftingCompass, FaHardHat, FaRegBuilding, FaTruckMoving } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
/* import thumb1 from '../../assets/img/service1.jpg';
import thumb2 from '../../assets/img/service2.jpg';
import thumb3 from '../../assets/img/service3.jpg';
import thumb4 from '../../assets/img/service4.jpg'; */
/* import thumb5 from '../../assets/img/service5.jpg';
import thumb6 from '../../assets/img/service6.jpg'; */

import thumb1 from '../../assets/img/services/Oil-and-Gas-Trading.jpg';
import thumb2 from '../../assets/img/services/2.webp';
import thumb3 from '../../assets/img/services/marine-logistics.jpg';
import thumb4 from '../../assets/img/services/vessel.jpg';
import thumb5 from '../../assets/img/services/pumpjack-oil-well.jpg';
import thumb6 from '../../assets/img/services/Oil-Bunkering2.webp';

const servicesTwoData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        icon: <FaDraftingCompass />,
        heading: 'Oil & Gas Trading',
        text: 'At Florence Petroleum Ltd, we aim to balance this instability in the oil and gas industry by ensuring the availability of petroleum products at procurable prices.',
        timer: '0.8s'
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        icon: <FaRegBuilding />,
        heading: 'Oil & Platform Services',
        text: 'Florence Petroleum Ltd offers an array of oil and platform services like: Conversion and upgrade of rigs, Floating storage units and Floating production storage and offloading units.',
        timer: '1.2s'
    },
    
    {
        id: uuidv4(),
        thumbnail: thumb5,
        icon: <FaCity />,
        heading: 'Oil Derivatives',
        text: 'Consumers do not buy raw crude oil, but petroleum products like gasoline, heating oil, diesel and jet fuel. This crude oil is formed under specific environmental conditions from fossils in a process which...',
        timer: '1.6s'
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        icon: <FaTruckMoving />,
        heading: 'Marine Logistics',
        text: "At Florence Petroleum Ltd, we employ the latest technology so as to be at the forefront of innovation in anticipation of our esteemed client's needs",
        timer: '2s'
    },

    {
        id: uuidv4(),
        thumbnail: thumb4,
        icon: <FaRegBuilding />,
        heading: 'Vessel Chattering',
        text: 'We provide vessel charter for all routes; both commercial and non-commercial liners. With vessels of all sizes and lifting capabilities, to Ro/Ro, container carriers and gearless ships',
        timer: '2.4s'
    },

    {
        id: uuidv4(),
        thumbnail: thumb6,
        icon: <FaHardHat />,
        heading: 'Oil Bunkering',
        text: 'Due to our large reserve and efficient platform services, we store and supply fuels to vessels in both the private and commercial sectors. Our bunker delivery operations ensure that we cater to a... ',
        timer: '2.8s'
    },
];

export default servicesTwoData;
