import userImg from '../../assets/img/blog/author2.jpg';
import chart from '../../assets/img/chart.png';

const analyticsData = {
    heading: 'Can shipping navigate to net zero?',
    subHeading:
        'It’s the backbone of global trade, but there’s a challenge when it comes to lowering carbon emissions. What will it take to get shipping in to shape?',
    userName: 'Miranda H.',
    userPosition: 'Founder',
    userImg,
    userPhone: '963. 369. 265. 56',
    chart,
};

export default analyticsData;
