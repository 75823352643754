import React from 'react';
import { BsEyeFill, BsCloudDownload, BsPersonSquare } from 'react-icons/bs';
import { Link } from 'react-router-dom';
//import AgeImg from '../../assets/img/pexels/4b.jpg';
import Pdf from '../../assets/docs/florenceprofile.pdf';

function Promo() {
    return (
        <section className="promo-featured-wrapper section-padding">
            <div className="container">
                <div className="row align-center">
                    <div className="col-xl-6 col-12 text-center wow fadeInUp" data-wow-duration='1.4s' style={{backgroundSize: '90% auto', backgroundPositionX: 'initial'}}>
                       <div className="reduce-iframe-width" style={{width: 'auto'}}>
                       {/*  <img src={AgeImg} style={{borderRadius: "10px"}} alt="age" /> */}
                       <iframe width="110%" height="400px" style={{}} src="https://www.youtube.com/embed/BP5tq5PEryI" title="florence Petroleum" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                       </div> 
                      {/*   <h5> */}
                            
                            <div className="sub-main wow fadeInUp" data-wow-duration="1s" align="left">
                            <Link to="/about-founder">  <button className="button- btn btn-danger"><BsPersonSquare style={{ fontSize: '20px', marginLeft: '15px' }} /> <span> Click To View Dr. Henry Profile</span> </button></Link>
                            </div>
                               
                                
                      {/*   </h5> */}
                    </div>
                    <div className="col-xl-5 col-12 shift-righter">
                        <div className="block-contents ml-xl-5 mt-5 mt-xl-0  wow fadeInUp" data-wow-duration='0.4s'>
                            <span>An Icon In Petroleum Industry</span>
                            <h2>Florence Petroleum</h2>
                            <h4 style={{display: 'inline'}}>
                                About Us
                            </h4>
                            <p>
                            Florence Petroleum Ltd is an oil and gas company with interests in bulk trading of petroleum products in the Nigerian downstream sector. We procure petroleum products from offshore depots and store in tank farms, through various throughput arrangements with tank farms across the coastal areas of Nigeria and West African Sub-Region. 
                            </p>
                            <p>Our tank farm is about 90% completed, and is located at Koko Free Trade Zone of Delta State. This ensures constant supply and servicing of our client demands.</p>
                            <span style={{display: 'flex', gap: '10px', marginTop: '15px'}}>
                            <Link to="/about" className="btn btn-success">
                                View more
                                <BsEyeFill style={{ fontSize: '20px', marginLeft: '15px' }} />
                            </Link>
                            <a href={Pdf} target="_blank" rel="noreferrer" className="btn btn-primary">
                                Download Our Profile
                                <BsCloudDownload style={{ fontSize: '20px', marginLeft: '15px' }} />
                            </a>
                           
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Promo;
