import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';

const WidgetGetinTouchData = [
    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Phone Number',
        text: '+2348034045673',
    },

    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Email',
        text: 'info@florencepetroleum.com.ng',
    },

    {
        id: uuidv4(),
        icon: <BiMap />,
        heading: 'Address',
        text: '4th floor, Churchgate Tower, Plot 473 Constitution Ave, Central Business District 900211, Abuja FCT, Nigeria',
    },
];

export default WidgetGetinTouchData;
