import React, {useEffect} from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Zoom } from "react-slideshow-image";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import HeroBg2 from '../../assets/img/pexels/14edited.jpg';
import HeroBg3 from '../../assets/img/pexels/15edited.jpg';
import HeroBg4 from '../../assets/img/pexels/16edited.jpg';
import HeroBg5 from '../../assets/img/pexels/3edited.jpg';
import HeroBg6 from '../../assets/img/pexels/10edited.jpg';
import HeroBg7 from '../../assets/img/pexels/slider/4.jpg';
/* import HeroBg8 from '../../assets/img/pexels/slider/5.jpg'; */
import HeroBg9 from '../../assets/img/pexels/slider/6.jpg';
import HeroBg10 from '../../assets/img/pexels/slider/1a.jpg';
import HeroBg11 from '../../assets/img/pexels/slider/2a.jpg';
import HeroBg12 from '../../assets/img/pexels/slider/3a.jpg';


const images = [HeroBg2, HeroBg9, HeroBg5, HeroBg6, HeroBg7, HeroBg10, HeroBg11, HeroBg12];

const zoomOutProperties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  scale: 0.4,
  arrows: true
};



const Slideshow = () => {

    return (
      <div className="slide-container">
        <Zoom {...zoomOutProperties}>
          {images.map((each, index) => (
            <>
                <img key={index} style={{ width: "100%" }} src={each} alt="slideshow"/>
                <div className="slider-texter wow fadeInUp" data-wow-duration='0.8s' style={{position: 'relative', top: '-325px', left: '80px',  fontSize: '0.9rem', fontWeight: '500px', color: '#f5f8fa'}}><h2 class='slidertexter'>
                    <span className='wow fadeInUp' data-wow-duration='0.1s'>W</span>
                    <span className='wow fadeInDown' data-wow-duration='0.3s'>e</span>
                    <span className='wow fadeInUp' data-wow-duration='0.6s'>l</span>
                    <span className='wow fadeInDown' data-wow-duration='0.9s'>c</span>
                    <span className='wow fadeInUp' data-wow-duration='1.2s'>o</span>
                    <span className='wow fadeInDown' data-wow-duration='1.5s'>m</span>
                    <span className='wow fadeInUp' data-wow-duration='1.8s'>e</span>&nbsp; 
                    <span className='wow fadeInDown' data-wow-duration='2.1s'>To</span>&nbsp; 
                    <span className='wow fadeInUp' data-wow-duration='2.4s'>F</span>
                    <span className='wow fadeInDown' data-wow-duration='2.8s'>l</span>
                    <span className='wow fadeInUp' data-wow-duration='3.2s'>o</span>
                    <span className='wow fadeInDown' data-wow-duration='3.6s'>r</span>
                    <span className='wow fadeInUp' data-wow-duration='4s'>e</span>
                    <span className='wow fadeInDown' data-wow-duration='4.3s'>n</span>
                    <span className='wow fadeInUp' data-wow-duration='4.6s'>c</span>
                    <span className='wow fadeInDown' data-wow-duration='4.9s'>e</span>&nbsp; 
                    <span className='wow fadeInUp' data-wow-duration='5.2s'>P</span>
                    <span className='wow fadeInDown' data-wow-duration='5.5s'>e</span>
                    <span className='wow lightInSpeed' data-wow-duration='5.8s'>t</span>
                    <span className='wow lightInSpeed' data-wow-duration='6.2s'>r</span>
                    <span className='wow lightInSpeed' data-wow-duration='6.6s'>o</span>
                    <span className='wow lightInSpeed' data-wow-duration='7s'>l</span>
                    <span className='wow lightInSpeed' data-wow-duration='7.3s'>e</span>
                    <span className='wow lightInSpeed' data-wow-duration='7.6s'>u</span>
                    <span className='wow lightInSpeed' data-wow-duration='8s'>m...</span></h2></div>
                {/* <div style={{position: 'absolute', top: '-120px', right: '20px', color: '#fff', fontSize: '25px', zIndex: '999999999'}}><h2>Florence Petrolem is a Primus Interpares</h2></div> */}
            </>
          ))}
        </Zoom>
      
      </div>
    );
  };


function Hero1() {
    return (
        <>
        {/* <div id="overlay" onclick="on()"></div> */}
        <section className="hero-slide-wrapper hero-1" style={{top: '-40px'}}>
             <Slideshow />
             {/* <div style={{width: '100%', height: '100%', backgroundColor: '#000', opacity: '0.6', zIndex: '99999999'}}></div>
            <Swiper className="hero-slider-active owl-carousel">
                <SwiperSlide> 
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg1})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <div className="hero-contents">
                                        <h1 style={{fontSize: '6em'}}>Florence Petroleum</h1>
                                        <Link to="/services" className="theme-btn">
                                            Our Services{' '}
                                            <BsArrowRight style={{ marginLeft: '10px' }} />
                                        </Link>
                                        <Link to="/about" className="theme-btn black">
                                            Learn more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div
                        className="single-slide bg-cover"
                        style={{ backgroundImage: `url(${HeroBg2})` }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <div className="hero-contents">
                                        <h1  style={{fontSize: '6em'}}>Florence Petroleum</h1>
                                        <a href="services.html" className="theme-btn">
                                            Our Services <i className="fal fa-long-arrow-right" />
                                        </a>
                                        <a href="about.html" className="theme-btn black">
                                            learn more
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper> */}
         </section> 
       </>
    );
}

export default Hero1;
