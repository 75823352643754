import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/blog/shell2';
import thumb2 from '../../assets/img/blog/shell1';
import thumb3 from '../../assets/img/blog/shell3';

const blogOneData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        date: '28',
        month: 'Sep, 2022',
        category: 'Industrial',
        author: 'Chuks David',
        title: 'Shell buys major Nigeria solar power player...',
        link: '/news-details',
        timer: '1.2s'
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        date: '12',
        month: 'Aug, 2022',
        category: 'Industrial',
        author: 'Chuks David',
        title: 'Shell warns policymakers that cutting oil and...',
        link: '/news-details',
        timer: '1.4s'
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        date: '04',
        month: 'Nov, 2022',
        category: 'Industrial',
        author: 'Chuks David',
        title: 'Guyana to auction 14 offshore blocks...',
        link: '/news-details',
        timer: '2s'
    },
];

export default blogOneData;
