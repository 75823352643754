import React from 'react';
import { BiCalendar } from 'react-icons/bi';
import { Link } from 'react-router-dom';

function FeedsWidget({ postLink, thumbnail, heading, meta }) {
    const postLinker = +postLink;
    return (
        <div className="single-post-item">
            <div
                className="thumb bg-cover"
                style={{
                    backgroundImage: `url(${thumbnail})`,
                }}
            />
            <div className="post-content">
                <h5>
                    {/* <Link onClick={() => window.location.replace('./news-details#qs'+postLinker)}>{heading}</Link> */}
                    <a href={'#qs'+postLinker}>{heading}</a>
                </h5>
                <div className="post-date">
                    <BiCalendar />
                    {meta}
                </div>
            </div>
        </div>
    );
}

export default FeedsWidget;
