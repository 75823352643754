import React, { useState, useEffect } from 'react';
import { FaHardHat, FaPlay, FaRoad } from 'react-icons/fa';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import aboutImg from '../../assets/img/homeimg.png';
import btnImg from '../../assets/img/skill_bg2.png';
import IconBox from './IconBox';
/* import {useLocation} from 'react-router-dom' */

function AboutFeatured() {
    const [isOpen, setOpen] = useState(false);

    return (
        <>

            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="N3iSUaBAq00"
                onClose={() => setOpen(false)}
            />
            <section className="about-featured-wrapper section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-12">
                            <div
                                className="about-promo bg-cover"
                                style={{ backgroundImage: `url(${aboutImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                            >
                                <div
                                    className="skill-popup-video d-flex justify-content-center align-items-center bg-cover  fadeIn" data-wow-duration='0.8s' 
                                    style={{ backgroundImage: `url(${btnImg})`, backgroundSize: '100% auto' }}
                                >
                                    <div className="video-play-btn wow rotateIn" data-wow-duration='1s' >
                                        <button
                                            type="button"
                                            className="modal-btn"
                                            onClick={() => setOpen(true)}
                                        >
                                            <FaPlay />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
                      {/*   <ReactWOW animation='fadeInUp'> */}<div className="block-contents ml-lg-5" >
                                <span>An Icon In Petroleum Industry</span>
                                <h1>Florence Petroleum</h1>
                                <h3>
                                About Us
                                </h3>
                            </div>
                        {/* </ReactWOW> */}
 

                            <div className="icon-boxs ml-lg-5">
                                <IconBox
                                    icon={<FaHardHat />}
                                    heading="Who We Are"
                                    timer="1.2s"
                                    text="Florence Petroleum Ltd is an oil and gas company with interests in bulk trading of petroleum products in the Nigerian downstream sector. We procure petroleum products from offshore depots and store in tank farms, through various throughput arrangements with tank farms across the coastal areas of Nigeria and West African Sub-Region.

                                    Our tank farm is about 90% completed, and is located at Koko Free Trade Zone of Delta State. This ensures constant supply and servicing of our client demands."
                                />
                                <IconBox
                                    icon={<FaRoad />}
                                    heading="Creative Project"
                                    timer="1.8s"
                                    text="Our tank farm is about 90% completed, and is located at Koko Free Trade Zone of Delta State. This ensures constant supply and servicing of our client demands."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutFeatured;
