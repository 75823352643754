import React, {useEffect} from 'react';
import bannerBg from '../assets/img/page-banner.jpg';
import ContactForm from '../components/ContactForm';
import FaqAccordion from '../components/FaqAccordion';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import WOW from 'wowjs'

function Faq() {
    useEffect(() => {
        let f = false;
        if(!f) {
          new WOW.WOW().init();
        }
        return () => {
          f = true
        }
      }, [])
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} heading="Get Answers" currentPage="Faq" />
            <section className="faq-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInUp wow fadeInUp" data-wow-duration='0.2s' >
                            <div className="faq-content">
                                <FaqAccordion
                                    question="What forms of payment do you accept?"
                                    answer="We accept all major credit and debit cards, Paypal, and Electronic Funds Transfer (EFT)."
                                />
                                <FaqAccordion
                                    question="Can I pay for my order over the phone?"
                                    answer="No, for payment security purposes, all orders must be placed online through our website."
                                />
                                <FaqAccordion
                                    question="Can I track  products from the tank farm?"
                                    answer="Yes, just download our tank farm app to track your products"
                                />
                                <FaqAccordion
                                    question="What is your average lead time for order processing?"
                                    answer="Orders recieved will be processed within 24 to 48 hours. If you have  questions regarding your order please call the numbers on our website"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp">
                            <div className="faq-content">
                                <FaqAccordion
                                    question="Where is my order shipping from?"
                                    answer="Most orders will ship from our warehouse in Wilkes-Barre, PA.."
                                />
                                <FaqAccordion
                                    question="Will I be notified when my order ships?"
                                    answer="Can I arrange to have my own shipping company pick up my product?"
                                />
                                <FaqAccordion
                                    question="How do I know if a product is available and ready to ship?"
                                    answer="While most products we show as available to order on our site are in stock, there are situations where inventory can run out. In those situations, customers will be notified by email. Additionally, we guarantee your product price when the product is replenished."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='wow fadeInUp' data-wow-duration='0.2s'>
            <ContactForm title="Get Quote" heading="Get More Answers" />
            </div> 
            <Footer1 />
        </>
    );
}

export default Faq;
