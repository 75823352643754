import React from 'react';
import { BiCalendar } from 'react-icons/bi';
import { BsEye } from 'react-icons/bs';
import { FaRegComments } from 'react-icons/fa';
import thumb1 from '../../assets/img/blog/shell2';
import thumb2 from '../../assets/img/blog/shell1';
import thumb3 from '../../assets/img/blog/shell3';

function SinglePost() {
    return (
        <div className="single-blog-post post-details wow fadeInUp" data-wow-duration="1.4s">
            <div className="post-content">
                <div className="post-cat">
                    <a href="news.html">Business</a>
                </div>
                  {/* blog  1 */}
                  <img src={thumb1} alt="" id="qs0" />
                <h2 >Shell buys major Nigeria solar power player</h2>
                <div className="post-meta  wow fadeInUp" data-wow-duration="1.4s">
                    <span>
                        <BsEye
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        232 Views
                    </span>
                    <span>
                        <FaRegComments
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        35 Comments
                    </span>
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        28th September 2022
                    </span>
                </div>
                <p className="wow fadeInUp" data-wow-duration="0.8s">
                    Shell has struck a milestone deal to buy West African solar company Daystar Power, a milestone agreement for the supermajor because it represents its first power business acquisition in Africa.
                </p>
                <p className="wow fadeInUp" data-wow-duration="0.8s">
                    Lagos-based Daystar, which provides hybrid solar power solutions to commercial and industrial (C&I) businesses, said today that it had been bought by Shell for an undisclosed amount in a deal subject to regulatory approvals.
                </p>
                <p className="wow fadeInUp" data-wow-duration="0.8s">
                    Daystar’s co-founders and management team will continue to grow operations in its key West African markets, while expanding its presence to east and southern Africa.
                </p>
                <br/><br/>


                {/* blog  2 */}
                <img className="alignleft" src={thumb2} alt="" />  
                <h2  id="qs1">Shell completes $1.55 billion take-over of Indian renewable energy platform Sprng</h2>
                <div className="post-meta wow fadeInUp" data-wow-duration="1.4s">
                    <span>
                        <BsEye
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        232 Views
                    </span>
                    <span>
                        <FaRegComments
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        35 Comments
                    </span>
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        12th August 2022
                    </span>
                </div>
                <p className="wow fadeInUp" data-wow-duration="0.8s">
                    Supermajor Shell has completed its $1.55 billion acquisition of Indian renewables developer and operator Sprng Energy from Actis Solenergi.
                </p>
                <blockquote className="wow fadeInUp" data-wow-duration="0.8s">
                    The purchase via unit Shell Overseas Investment triples its renewable capacity in operation, and will help Shell to reach its target of becoming a net-zero emissions energy business by 2050, the company said.
                </blockquote>
                <p>
                    Pune-based Sprng is one of India’s fastest-growing wind and solar companies, with 2.9 gigawatts in operating and contracted assets and another 7.5GW in its development pipeline.
                </p>

                <ul type="circle">
                    <li>Shell in late April had announced the signing of an agreement to purchase the Solenergy Power Private and Sprng Group of companies.</li>

                    <li>The supermajor previously had been tipped in the Indian media as a suitor for Sprng, and is said to have seen off finance giant Macquarie and Canadian pension fund CPPIB in bidding for the developer.</li>

                    <li>India is pursuing some of the world’s most ambitious green growth targets and badly needs major foreign investors to help its push towards 500 GW of installed renewables capacity by 2030, from about 160 GW now.</li>

                    <li>Shell’s fellow European supermajor TotalEnergies has an alliance with Indian conglomerate Adani, while other power sector majors such as Enel are active in the nation’s wind and solar sector.</li>
                </ul>
          
                <h3>Shell is  expands its global interests in renewables</h3>
                <p>
                    Shell is gradually expanding its global interests in renewables and other clean energy assets as part of a corporate energy transition strategy that currently earmarks $2 billion to $3 billion annually for low-carbon investments.
                </p>
                <br/><br/>

                 {/* blog  3 */}
                <img className="alignleft" src={thumb3} alt="" />
                <h3 id="qs2">Guyana to auction 14 offshore blocks using revamped royalty scheme</h3>
                <p  className="wow fadeInUp" data-wow-duration="1.4s">
                <div className="post-meta">
                    <span>
                        <BsEye
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        204 Views
                    </span>
                    <span>
                        <FaRegComments
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        15 Comments
                    </span>
                    <span>
                        <BiCalendar
                            style={{
                                marginRight: '5px',
                                fontSize: '18px',
                                marginTop: '-2px',
                            }}
                        />
                        4th November 2022
                    </span>
                </div>
                The Guyanese government has unveiled plans to offer 14 offshore exploration blocks to investors in a bid round scheduled to take place next year, and has introduced new fiscal terms in an attempt to raise more money from the oil sector.
                </p>

                <p>                
                Guyana had initially aimed to stage the competitive bid round this year, but Vice President Bharrat Jagdeo said extensive preparatory work had to be completed in a fast-moving global situation.
                </p>
                <p> 
                Jagdeo said potential bidders will be provided with terms and conditions before the auction is formally launched.
                </p>
                



                
            </div>
        </div>
    );
}

export default SinglePost;
