import React from 'react';
import { FaComments } from 'react-icons/fa';
import thumb1 from '../../assets/img/blog/shell2';
import thumb2 from '../../assets/img/blog/shell3';
import BlogSidebarFounder from '../BlogSidebar/indexFounder';
import CommentInput from './CommentInput';
import Comments from './Comments';
import RelatedPost from './RelatedPost';
import SinglePost from './SinglePost';
import TagShare from './TagShare';

function BlogDetails() {
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    return (
        <section className="blog-wrapper news-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="blog-post-details border-wrap">

                            <SinglePost />
                            
                            <TagShare
                                tag1="PMS"
                                tag2="Kerosine"
                                tag3="Diesel"
                                tag4="Lubricant"
                                fbLink="/"
                                twitterLink=""
                                instaLink=""
                                linkedInLink=""
                            />

                            <div className="related-post-wrap">
                                <h3>Releted Post</h3>

                                <div className="row">
                                    <RelatedPost
                                        thumbnail={thumb1}
                                        postDate="27th March 2021"
                                        title="Shell buys major Nigeria solar power player"
                                        text="Shell has struck a milestone deal to buy West African solar company Daystar Power, a milestone agreement for the supermajor because it represents its first power business acquisition in"
                                        postLink="#qs0"
                                    />
                                    <RelatedPost
                                        thumbnail={thumb2}
                                        postDate="4th November 2022"
                                        title="Guyana to auction 14 offshore blocks..."
                                        text="The Guyanese government has unveiled plans to offer 14 offshore exploration blocks to investors in a bid round scheduled to take place next year, and has introduced new fiscal terms in an attempt to...
                                        "
                                        postLink="#qs2"
                                    />
                                </div>
                            </div>
                            {/* COMMENTS */}
                            <Comments />
                            <div className="comment-form-wrap mt-40">
                                <h3>Post Comment</h3>

                                <form action="#" className="comment-form">
                                    <div className="single-form-input">
                                        <textarea placeholder="Type your comments...." />
                                    </div>
                                    <CommentInput type="text" placeholder="Type your name..." />
                                    <CommentInput type="email" placeholder="Type your email..." />
                                    <CommentInput type="text" placeholder="Type your websitee..." />
                                    <button
                                        onSubmit={handleSubmit}
                                        className="submit-btn"
                                        type="submit"
                                    >
                                        <FaComments
                                            style={{ marginTop: '-5px', marginRight: '10px' }}
                                        />
                                        Post Comment
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* SIDEBAR */}
                    <BlogSidebarFounder />
                </div>
            </div>
        </section>
    );
}

export default BlogDetails;
