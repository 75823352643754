import React, { useState } from 'react';
import { FaUser, FaPlay, FaProjectDiagram } from 'react-icons/fa';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import aboutImg from '../../assets/img/team/Dr_Okechukwu_Obi.jpg';
import btnImg from '../../assets/img/skill_bg2.png';
import IconBoxFounder from './IconBoxFounder';

function AboutFounder() {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="UQItuqgMIHY"
                onClose={() => setOpen(false)}
            />
            <section className="about-featured-wrapper section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-12">
                            <div
                                className="about-promo bg-cover"
                                style={{ backgroundImage: `url(${aboutImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                            >
                                <div
                                    className="skill-popup-video d-flex justify-content-center align-items-center bg-cover"
                                    style={{ backgroundImage: `url(${btnImg})`, backgroundSize: '100% auto' }}
                                >
                                    <div className="video-play-btn">
                                        <button
                                            type="button"
                                            className="modal-btn"
                                            onClick={() => setOpen(true)}
                                        >
                                            <FaPlay />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
                      {/*   <ReactWOW animation='fadeInUp'> */}<div className="block-contents ml-lg-5" >
                                <span>An Icon In Petroleum Industry</span>
                                <h1>Florence Petroleum</h1>
                                <h3>
                                About The MD/CEO
                                </h3>
                            </div>
                        {/* </ReactWOW> */}
 

                            <div className="icon-boxs ml-lg-5">
                                <IconBoxFounder
                                    icon={<FaUser />}
                                    heading="Who Is Dr. Henry Obi"
                                    timer="1.2s"
                                    btnText = "Read More"
                                    text="Dr. Okechukwu Henry Obi, a Nigerian oil magnate based abroad, has been vocal about the need for holistic safety in the maritime space
                                    Nigeria’s maritime domain is not just a rich treasure trove of minerals and resources, but also a major factor in trans-atlantic trading.

                                    However, it’s been burdened by maritime insecurity, piracy, poor dredging, inadequate berthing space for mother vessels, poor dredging challenges, dock problems, reduced channel width due to silting and unwholesome obstacles, amongst others.
                                    "
                                    pageLink = '/founder-details'
                                />
                                <IconBoxFounder
                                    icon={<FaProjectDiagram />}
                                    heading="Deep Blue Project"
                                    timer="1.8s"
                                    btnText = "Read More"
                                    text="On the Deep Blue Project, he commended government’s efforts. He said: “Presently the government is doing well with the launch of the deep blue project which, in my honest opinion, is the beginning of good things to come. Right now, there has been reduced incidence of sea piracy attacks up to the Gulf of Guinea."
                                    pageLink = '/founder-details'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutFounder;
