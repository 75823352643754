import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import brandData from './brandData';
import BrandItem from './BrandItem';

function BrandCarousel() {
    return (
        <>
            <h1 align="center" style={{position: 'relative', top: '40px'}}>Our Subsidiaries...</h1>
            <section style={{marginTop: '7%', maxHeight: '14vh'}}>
            {/* <div className="brand-wrapper section-bg mb-0 pt-20 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="brand-logo-grid">
                                <Swiper slidesPerView="4" loop>
                                    {brandData.map((data) => (
                                        <SwiperSlide key={data.id}>
                                            <BrandItem brandLogo={data.brandLogo} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                   <div className="position-relative marquee-container d-nonex d-sm-block" style={{display: 'inline-inline'}}>
  <div className="marquee d-flex justify-content-around" style={{marginRight: '12px'}}>
    <img src={brandData[0].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
    <img src={brandData[1].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
    <img src={brandData[2].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
    <img src={brandData[3].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
  </div>

 {/*  <div className="marquee marquee2 d-flex justify-content-around"  style={{marginLeft: '12px'}}>
  <img src={brandData[0].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
    <img src={brandData[1].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
    <img src={brandData[2].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
    <img src={brandData[3].brandLogo} alt="1"  style={{maxWidth: '200px', position: 'relative', top: '20px'}}/>
  </div> */}
</div>
        </section>

 
    </>
    );
}

export default BrandCarousel;
