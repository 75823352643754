import React from 'react';
import bannerBg from '../assets/img/page-banner.jpg';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import FounderDetails from '../components/FounderDetails';

function FounderDetail() {
    return (
        <>
            <Header1 />
            <PageBanner
                bannerBg={bannerBg}
                heading="Founder Details"
                currentPage="Founder Details"
            />

            <FounderDetails />
            
            <Footer1 />
        </>
    );
}

export default FounderDetail;
