import React from 'react';

function WidgetGetinTouch({ index, heading, text, icon }) {
    return (
        <>
            <div className="single-contact-info wow fadeInRight" data-wow-duration="1.8s">
                <div className={`icon${index === 1 || index === 2 ? ' locicon':''}`} id={`${index === 2 && 'locid'}`}>{icon}</div>
                <div className="contact-info">
                    <span>{heading}</span>
                    <p>{text}</p>
                </div>
            </div>
        </>
    );
}

export default WidgetGetinTouch;
