import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollIndicator from './components/ScrollIndicator';
import ScrollToTopRoute from './components/ScrollTopRoute';
import About from './pages/About';
import AboutFounder from './pages/AboutFounder';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Home1 from './pages/Home1';
import Home2 from './pages/Home2';
import Home3 from './pages/Home3';
import News from './pages/News';
import NewsDetails from './pages/NewsDetails';
import ProjectDetails from './pages/ProjectDetails';
import FounderDetails from './pages/FounderDetail';
import Projects from './pages/Projects';
import Services from './pages/Services';
import ServicesDetails from './pages/ServicesDetails';
import Team from './pages/Team';



function App() {
    return (
        <>
            <Router basename="/">
                <ScrollToTopRoute />
                <Routes>
                    <Route exact path="/home-2"  element={<Home2/>}/>
                        {/* <Home2 />
                    </Route> */}
                    <Route exact path="/home-3" element={<Home3/>}/>
                       {/*  <Home3 />
                    </Route> */}
                    <Route exact path="/about"  element={<About/>}/>
                        {/* <About />
                    </Route> */}
                    <Route exact path="/about-founder"  element={<AboutFounder/>}/>
                        {/* <About />
                    </Route> */}
                    <Route exact path="/founder"  element={<AboutFounder/>}/>
                        {/* <About />
                    </Route> */}
                    <Route exact path="/team" element={<Team/>}/>
                       {/*  <Team />
                    </Route> */}
                    <Route exact path="/faq" element={<Faq/>}/>
                       {/*  <Faq />
                    </Route> */}
                    <Route exact path="/projects" element={<Projects/>}/>
                       {/*  <Projects />
                    </Route> */}
                    <Route exact path="/services" element={<Services/>}/>
                       {/*  <Services />
                    </Route> */}
                    <Route exact path="/services-details" element={<ServicesDetails/>}/>
                       {/*  <ServicesDetails />
                    </Route> */}
                    <Route exact path="/project-details" element={<ProjectDetails/>}/>
                        {/* <ProjectDetails />
                    </Route> */}
                    <Route exact path="/founder-details" element={<FounderDetails/>}/>
                        {/* <ProjectDetails />
                    </Route> */}
                    <Route exact path="/news" element={<News/>}/>
                       {/*  <News />
                    </Route> */}
                    <Route exact path="/news-details" element={<NewsDetails/>}/>
                       {/*  <NewsDetails />
                    </Route> */}
                    <Route exact path="/contact" element={<Contact/>}/>
                        {/* <Contact />
                    </Route> */}
                    <Route exact path="/"  element={<Home1/>}/>
                   {/*      <Home1 />
                    </Route> */}
                </Routes>
            </Router>
            <ScrollIndicator />
        </>
    );
}

export default App;
