import React from 'react';

function Topbar() {
    return (
        <div className="top-bar d-none d-md-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-12">
                        <a href="/" className="top-left">
                            Working hours: <span>Mon - Sat 8.00 - 19.00</span>
                        </a>
                       
                    </div>
                    <div className="col-lg-3 col-12 d-none d-lg-block text-lg-right">
                        <div className="lan-select" style={{display: 'flex'}}>
                        <a href="/" className="top-left" style={{position: 'relative', top: '6px'}}>
                            Terms&nbsp;&#38;&nbsp;Conditions
                        </a>
                            <form>
                                <select id="lan">
                                    <option>English</option>
                                {/*     <option>China</option>
                                    <option>Bangla</option>
                                    <option>Hindi</option> */}
                                </select>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
