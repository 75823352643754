import React from 'react';
import bannerBg from '../assets/img/page-banner.jpg';
import AboutFloFounder from '../components/AboutFeatured/indexFounder';
import Approch from '../components/ApprochFounder';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import SkillSet from '../components/SkillSet';
import Sponsors from '../components/Sponsors';
import Timeline from '../components/Timeline';

function AboutFounder() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} currentPage="About The CEO" heading="About The CEO" />
            <AboutFloFounder />
            <Approch />
            <SkillSet />
            <Sponsors />
         {/*    <Timeline /> */}
            <Footer1 />
        </>
    );
}

export default AboutFounder;
