import React from 'react';
import man1 from '../../assets/img/man1.png';
import man2 from '../../assets/img/man2.png';
import man3 from '../../assets/img/man3.png';
import bgImg from '../../assets/img/gallery/faq.jpg';
import FaqAccordion from '../FaqAccordion';

function Faq() {
    return (
        <section className="faq-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-12">
                        <div className="content-blockwow fadeInUp" data-wow-duration="1.4s">
                            <p><b>Florence Question and Answers</b></p>
                            <h1>Get every single answers from here.</h1>
                            <div className="bg-img">
                                <img src={bgImg} alt="" />
                                <div
                                    className="man bg-cover man-1"
                                    style={{
                                        backgroundImage: `url(${man1})`,
                                    }}
                                />
                                <div
                                    className="man bg-cover man-2"
                                    style={{
                                        backgroundImage: `url(${man2})`,
                                    }}
                                />
                                <div
                                    className="man bg-cover man-3"
                                    style={{
                                        backgroundImage: `url(${man3})`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-12 mt-4 mt-xl-0wow fadeInUp" data-wow-duration="1.4s">
                        <div className="faq-content">
                        <FaqAccordion
                                    question="What forms of payment do you accept?"
                                    answer="We accept all major credit and debit cards, Paypal and Electronic Funds Transfer (EFT)."
                                />
                                <FaqAccordion
                                    question="Can I pay for my order over the phone?"
                                    answer="No, for payment security purposes, all orders must be placed online through our website."
                                />
                                <FaqAccordion
                                    question="Can I track  products from the tank farm?"
                                    answer="Yes, download our tank farm app to track your products."
                                />
                                <FaqAccordion
                                    question="What is your average lead time for order processing?"
                                    answer="Orders recieved will be processed within 24 to 48 hours if you have  questions regarding your order please call the numbers on our website."
                                />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;
