import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/blog/thumby1.png';
import thumb2 from '../../assets/img/blog/thumby2.png';
import thumb3 from '../../assets/img/blog/thumby3.png';
import thumb4 from '../../assets/img/blog/pp4.jpg';

const feedsData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        heading: 'Shell buys major Nigeria solar power...',
        meta: '28th September 2022',
        PostLink: 'qs0'
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        heading: 'Shell completes $1.55 billion take-over of Indian...',
        meta: '12th August 2022',
        PostLink: 'qs1'
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        heading: 'Guyana to auction 14 offshore blocks using...',
        meta: '4th November 2022',
        PostLink: 'qs2'
    },

    /* {
        id: uuidv4(),
        thumbnail: thumb4,
        heading: 'Lorem ipsum dolor sit cing elit, sed do.',
        meta: '24th March 2019',
    }, */
];

export default feedsData;
