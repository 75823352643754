import img1 from '../../assets/img/kappa.jpg';

const aboutTwoData = {
    img: img1,
    heading: 'Dustrix is always interested for doing awesome work.',
    quote: 'Capitalise on low hanging fruit to identify a ballpark value added activity to beta test with a new way in new situation.',
    author: 'Miranda H.',
    position: '- Founder',
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. fruit to identify a ballpark value added',
};

export default aboutTwoData;
