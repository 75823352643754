import React from 'react';
import ServicesOneCard from './ServicesOneCard';
import servicesOneData from './servicesOneData';
import Bg1 from '../../assets/img/pexels/10edited.jpg';

function Services1() {
    return (
        <section className="services-wrapper services-1 section-bg section-padding"  style={{backgroundImage: "url("+Bg1+")", backgroundSize: 'cover', backgroundAttachment: 'fixed'}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="section-title text-center">
                          {/*   <span>Services</span> */}
                            <p style={{color: 'white'}}>What we do</p>
                            <h1  style={{color: 'white'}}>Our Services</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {servicesOneData.map((item, index) => (
                        <ServicesOneCard
                            key={item.id}
                            bgImg={item.bgImg}
                            icon={item.icon}
                            heading={item.heading}
                            btnText={item.btnText}
                            anchor={index}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Services1;
