import { FaDraftingCompass, FaHardHat, FaPencilRuler } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/aboutfounder/waterway_dregging.jpg';
import thumb2 from '../../assets/img/aboutfounder/business_venture.jpg';
import thumb3 from '../../assets/img/aboutfounder/Youths-Protesting.jpg';
import thumb4 from '../../assets/img/aboutfounder/budding_entrepreneur.png';
import thumb5 from '../../assets/img/aboutfounder/security.jpeg';
import thumb6 from '../../assets/img/aboutfounder/quote.jpg';

const approchData = [
    {
        id: uuidv4(),
        thumb: thumb1,
        timer: "0.8s",
        icon: <FaDraftingCompass />,
        heading: 'Engaging Dredgers',
        text: 'On his stance about dredging the waterways, he said dredgers should be engaged in regular maintenance. Citing River Niger as the biggest and longest river in Nigeria, he said it was unfortunate it was left unattended to. He said: “The failure of ...',
        btnText: 'Read More',
        pageLink: '/founder-details#engagingdredgers',
    },

    {
        id: uuidv4(),
        thumb: thumb2,
        timer: "1.2s",
        icon: <FaPencilRuler />,
        heading: 'Venture into Business',
        text: 'On how he veered from medicine to business he said: “I have always loved business and entrepreneurship because of my parents business backgrounds. As a student, I have always engaged in one business venture or the other. I knew I...',
        btnText: 'Read More',
        pageLink: '/founder-details#businessventure',
    },

    {
        id: uuidv4(),
        thumb: thumb3,
        timer: "1.6s",
        icon: <FaHardHat />,
        heading: 'Making Mark in Nigeria',
        text: 'Noting that “Nigeria is a virgin forest, with population of over 200 million young and energetic youths, making good fortune in Nigeria is kind of guaranteed though there are so many challenges, and as you solve some of the...',
        btnText: 'Read More',
        pageLink: '/founder-details#makingmark',
    },

    {
        id: uuidv4(),
        thumb: thumb4,
        timer: "2s",
        icon: <FaHardHat />,
        heading: 'Advice to Budding Entrepreneurs',
        text: 'As an entrepreneur that has gone through the ropes he offered candid advice to budding entrepreneurs. “I will give to up- coming Nigerians that want to go into our kind of business or any kind of business for that matter, is to ...',
        btnText: 'Read More',
        pageLink: '/founder-details#buddingentrepreneurs',
    },

    {
        id: uuidv4(),
        thumb: thumb5,
        timer: "2.4s",
        icon: <FaHardHat />,
        heading: 'Charge on Security',
        text: 'Charging government to make Nigeria safe and secure, Obi said: “The only incentive I want government to give us is to make Nigeria safe and secure for everyone. Government should provide enabling environment for investors and entrepreneurs like ...',
        btnText: 'Read More',
        pageLink: '/founder-details#security',
    },

    {
        id: uuidv4(),
        thumb: thumb6,
        timer: "2.8s",
        icon: <FaHardHat />,
        heading: 'Obi\'s Quote',
        text: 'Nigeria being a country blessed with a great amount of littoral component states and waterways of great lengths, should strive very hard and concentrate its oil resources and revenues in developing a blue economy. Blue Economy an out perform any other form...',
        btnText: 'Read More',
        pageLink: '/founder-details#quote',
    },
];

export default approchData;
