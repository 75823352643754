import React from 'react';
import galleryImg1 from '../../assets/img/goals/vission.jpg';
import galleryImg2 from '../../assets/img/goals/mission.webp';
import galleryImg3 from '../../assets/img/goals/yellow-sticker-with-the-text-core-values-and-charts-photo.jpg';
import thumb from '../../assets/img/portfolio/project_details.jpg';
import detailsCardData from './detailsCardData';
import PortfolioDetailsCard from './PortfolioDetailsCard';
import PortfolioGallery from './PortfolioGallery';

function PortfolioDetails() {
    return (
        <section className="project-details-wrapper section-padding">
            <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-12">
                       {/*  <div
                            className="project-thumb bg-cover"
                            style={{
                                backgroundImage: `url(${thumb})`,
                            }}
                        /> */}
                        {/*   <div className="project-meta-data">
                            <div className="project-info">
                                <span>Clients</span>
                                <h3>Domal D. Williamson</h3>
                            </div>
                            <div className="project-info">
                                <span>Services</span>
                                <h3>Website Design</h3>
                            </div>
                            <div className="project-info">
                                <span>Keyword</span>
                                <h3>Development, UX, UI</h3>
                            </div>
                            <div className="project-info">
                                <a href="portfolio.html" className="theme-btn white">
                                    live preview
                                </a>
                            </div>
                        </div> */}
                        <div className="project-details-content">
                        <h3>Our Vision</h3>
                            <p>
                            To be the best company for products and services that exceeds our client's expectations as and when required Our Mission: To inspire the innovator in everyone. Our Vision: The Oil and Gas Sector, by what it does directly and through others, helps build a civil society . We say YES to CHALLENGES We value challenges because they produce innovations. We commit to approaching problems with enthusiasm and optimism. We say YES to TECHNOLOGY We celebrate the power of technology to transform lives. We commit to driving the oil and gas sector with the use of use technology . We say YES to FUN We are passionate about our work and about working together. We can't help but share this joyfulness with everyone. We say YES to INTEGRITY We value the trust and respect of our community and coworkers. We commit to becoming a place where we do what's right because we love what's right.
                            </p>
                           

                            <h3>Our Mission</h3>
                            <p>
                            To maximize our core values and increase our stakeholder's worth, while rendering consistent, excellent, quality and affordable service to our teeming clients through consistency, integrity and transparency. People are our most important asset. We value: Trust and mutual respect Integrity and professionalism Teamwork and mutual support Open communication Initiative and creativity Passion and resilience Individual and social responsibility SustainabilityWe aim to be judged as best in class by: Our Customers - to be their preferred supplier for Oil and Gas products and related services Our Employees - to be their preferred place to work Our Shareholders - to be their preferred long term investment Our Suppliers - to build strong mutually beneficial relationships based on trust, co-operation, innovation and sustainability Our Communities - to be a good neighbour, wherever we operate
                            </p>
                            <div className="row">
                                <PortfolioGallery galeryImg={galleryImg1} />
                                <PortfolioGallery galeryImg={galleryImg2} />
                                <PortfolioGallery galeryImg={galleryImg3} />
                            </div>

                            <h3>Our Core Values</h3>
                            <p>
                            At Florence Petroleum Ltd, these are our principles: Excellence: We run a well structured organization that ensures topnotch delivery, and optimum value for every service we render.Responsibility and Integrity: At Florence Petroleum Ltd, we pride ourselves in our transparent service delivery and ensure that we live up to expectation which is the hallmark of integrity. Dynamics: We are continuously developing ideas and changing the wheel in order to ensure an all-round customer satisfaction while catering to our client's demands. 
                            </p>
                            <p>
                            Driven: Our enthusiasm to excel is entrenched in our drive to stay afloat in highly competitive waters, and always be at the forefront of innovation because our clients deserve the best. We will achieve success by: We Ensure that all our actions add value and make our company sustainable Being obsessed with safety, in the belief that all accidents are preventable Following the highest standards of social and environmental responsibility.
                            </p>

                            {/* <h3>How We Solve This Project</h3>
                            <p>
                                Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehen derit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur.
                            </p> */}
                        </div>
                    </div>
                </div>
              {/*   <div className="row">
                    {detailsCardData.map((data) => (
                        <PortfolioDetailsCard
                            key={data.id}
                            icon={data.icon}
                            heading={data.heading}
                            num={data.num}
                            text={data.text}
                        />
                    ))}
                </div> */}
            </div>
        </section>
    );
}

export default PortfolioDetails;
