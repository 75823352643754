import React from 'react';
import MainMenu2 from './MainMenu2';
import Topbar2 from './Topbar2';

function Header2() {
    return (
        <>
            <Topbar2 />
            <MainMenu2 />
        </>
    );
}

export default Header2;
