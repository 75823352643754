import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/blog/b1.jpg';
import thumb2 from '../../assets/img/blog/b2.jpg';
import thumb3 from '../../assets/img/blog/b3.jpg';

const WidgetNewsData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        meta: '1st November 2022',
        heading: 'Smart energy: how clever will AI become?',
        link: '/news-details',
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        meta: '2nd November 2022',
        heading: 'Natural gas: a fuel fit for the future?',
        link: '/news-details',
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        meta: '2nd November 2022',
        heading: 'The Great Energy Debate: your questions answered',
        link: '/news-details',
    },
];

export default WidgetNewsData;
