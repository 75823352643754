import { v4 as uuidv4 } from 'uuid';

const processItemData = [
    {
        id: uuidv4(),
        number: '01',
        heading: 'GASOIL',
        text: 'Gasoil is a short form of Gasoline. Gas oil was originally used for heating homes and businesses before natural gas become more prevalent. But as time went on and motor vehicles became more widespread, gas oil also became a popular fuel for use in many vehicles in the early part of the 20th century.',
        timer: "0.8s"
    },
    
    {
        id: uuidv4(),
        number: '02',
        heading: 'PREMIUM MOTOR SPIRIT(PMS)',
        text: 'Premium Motor Spirit, also known as petrol or gasoline, is used to power internal combustion engines mostly in vehicles and generators. Generated from crude distillation, our PMS consists of organic compounds and additives that enhances engine performance.',
        timer: "1.6s"
    },

    {
        id: uuidv4(),
        number: '03',
        heading: 'DUAL PURPOSE KEROSINE',
        text: 'Kerosene, paraffin, or lamp oil is a combustible hydrocarbon liquid which is derived from petroleum. It is widely used as a fuel in aviation as well as households.',
        timer: "2.2s"
    },

    {
        id: uuidv4(),
        number: '04',
        heading: 'LPFO',
        text: 'LPFO is an acronym of Low Pour Fuel Oil. Fuel oil is any of various fractions obtained from the distillation of petroleum (crude oil). Such oils include distillates (the lighter fractions) and residues (the heavier fractions). Fuel oils include heavy fuel oil, marine fuel oil (MFO), bunker fuel, furnace oil, gas oil (gasoil), heating oils (such as home heating oil), diesel fuel and others.',
        timer: "2.8s"
    },

    {
        id: uuidv4(),
        number: '05',
        heading: 'BITUMEN',
        text: 'A sticky, black, highly viscous liquid or semi-solid form of petroleum, burning with a bright flame. It occurs as an abundant natural product in many places, as on the shores of the Dead and Caspian Seas. It is used in cements, in the construction of pavements, etc.',
        timer: "3.6s"
    },

];

export default processItemData;
