import React from 'react';
import analyticsData from './analyticsData';

function Analytics() {
    return (
        <section className="analytis-wrapper section-padding">
            <div className="container">
                <div className="row align-center">
                    <div className="col-xl-6 pr-xl-5 col-12 wow fadeInUp" data-wow-duration="1.4s">
                        <div className="block-contents">
                            <span>Business Analytics</span>
                            <h2>{analyticsData.heading}</h2>
                            <h4>{analyticsData.subHeading}</h4>
                            <p>{analyticsData.desc}</p>
                        </div>
                        <div className="user-data mr-45 d-flex align-items-center">
                            <div
                                className="user-img bg-cover"
                                style={{
                                    backgroundImage: `url(${analyticsData.userImg})`,
                                }}
                            />
                            <div className="user-info">
                                <h5>{analyticsData.userName}</h5>
                                <span>{analyticsData.userPosition}</span>
                            </div>
                            <div className="phone-info wow fadeInUp" data-wow-duration="1.4s">
                                <a href="/">{analyticsData.userPhone}</a>
                                <span>Make A Call</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-12 mt-5 mt-xl-0 wow fadeInUp" data-wow-duration="1.4s">
                        <div className="chart-wrapper">
                            <img src={analyticsData.chart} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Analytics;
