import React from 'react';

function Map() {
    return (
        <div id="map">
            <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.1622299196742!2d7.473013013809651!3d9.048962491169886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0b0c90624bcf%3A0xa1d6460ae41e8149!2sChurchgate%20Tower%20Abuja!5e0!3m2!1sen!2sng!4v1667696536325!5m2!1sen!2sng"
                frameBorder="0"
                style={{
                    border: '0',
                    width: '100%',
                }}
                allowFullScreen=""
                aria-hidden="false"
            />
            
        </div>
    );
}

export default Map;
