import React, {useEffect, useState} from 'react';
import { FaTh } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Logou from '../../assets/img/logo.png'
//import WOW from 'wowjs'

function MainMenu({ hanldeOffset }) {
    const [fix, setFix] = useState(false);
/* 
    useEffect(() => {
        let f = false;
        if(!f) {
          new WOW.WOW().init();
        }
        return () => {
          f = true
        }
    }, []) */

/*     function setFixed() {
        if(window.scrollY > 800) {
            setFix(true)
        } else {
            setFix(false)
        }
    }

    window.addEventListener("scroll", setFixed);
    const hbg = fix ? styles.headerbg : ''
    const mc = fix ? styles.menuColor : '' */
    return (
        <>
            <div className="main-menu-area"/*  style={{hbg}} */>
                <div className="row align-center">
                    <div className="col-6 col-md-8 col-lg-10">
                        <span style={{float: 'left', position: 'relative', top:'15px', marginRight:'10px', left:'2%'}}><img src={Logou} alt=""/></span>
                        <div className="main-menu" /*  style={{mc}} */>
                        <ul>
                                {/* data-wow-offset='0.5' data-wow-delay='0.2s' */}
                                <li className='wow fadeInUp' data-wow-duration='0.2s' >
                                    <Link to="/">Home</Link>
                                  {/*   <ul className="sub-menu">
                                        <li>
                                            <Link to="/">home 1</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-2">home 2</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-3">home 3</Link>
                                        </li>
                                    </ul> */}
                                </li>
                                <li className='wow fadeInUp' data-wow-duration='0.4s' >
                                    <Link to="/about">About</Link>
                                </li>
                                <li className='wow fadeInUp' data-wow-duration='0.4s' >
                                    <Link to="/team">team</Link>
                                </li>
                                <li className='wow fadeInUp' data-wow-duration='0.4s' >
                                    <Link to="/faq">faq</Link>
                                </li>
                                {/* <li className='wow fadeInUp' data-wow-duration='0.6s' >
                                    <Link to="/">Pages</Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/team">team</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">faq</Link>
                                        </li>
                                 
                                    </ul>
                                </li> */}
                                <li className='wow fadeInUp' data-wow-duration='0.8s' >
                                    <Link to="/services">Services</Link>
                                </li>
                                <li className='wow fadeInUp' data-wow-duration='1s' >
                                    <Link to="/news-details">News</Link>
                                </li>
                                <li className='wow fadeInUp' data-wow-duration='1.2s' >
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 col-md-8 col-lg-2 icon-wrapper">
                        {/* <button type="button" className="search-btn">
                            <i>
                                <FiSearch />
                            </i>
                        </button>*/}
                        <button type="button" onClick={hanldeOffset} className="side-menu-toggle wow slideInRight" data-wow-duration="2s">
                            <FaTh />
                        </button> 
                    </div>
                </div>
            </div>
        </>
    );
}

const styles = {
    headerbg: {
        position: 'fixed',
        width: '100%',
        backgroundColor: 'white'
    },
    menuColor: {
        color: '#222'
    }


}
export default MainMenu;
