import React, {useEffect} from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import WOW from 'wowjs'

function ApprochCard({ thumbnail, icon, heading, text, btnText, pageLink }) {
    useEffect(() => {
        let f = false;
        if(!f) {
          new WOW.WOW().init();
        }
        return () => {
          f = true
        }
    }, [])
    return (
        <>
            <div className="col-md-6 col-xl-4 col-12">
                <div className="single-approch-card wow zoomInUp" data-wow-duration='1.8s'>
                    <div
                        className="card-thumb bg-cover"
                        style={{
                            backgroundImage: `url(${thumbnail})`,
                        }}
                    />
                    <div className="content wow fadeInUp" data-wow-duration='1.8s'>
                        <div className="case-cat">
                            <Link to="/project-details">{icon}</Link>
                        </div>
                        <h3>
                            <Link to={pageLink}>{heading}</Link>
                        </h3>
                        <p>{text}</p>
                        <Link to={pageLink} className="read-btn">
                            {btnText} <BsArrowRight />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApprochCard;
