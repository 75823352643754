import { v4 as uuidv4 } from 'uuid';
import Img1 from '../../assets/img/services/upstream.png';
import Img2 from '../../assets/img/services/middlestream.png';
import Img3 from '../../assets/img/services/downstream.webp';
import Img4 from '../../assets/img/project/blackworker7.jpg';
import Img5 from '../../assets/img/project/blackworker8.jpg';
import Img6 from '../../assets/img/project/blackworker9.jpg';


const portfolioOneData = [
    {
        id: uuidv4(),
        image: Img1,
        category: 'Industrial',
        client: 'Gbenga Omoyeni',
        heading: 'Biofuels: where next?',
        desc: 'Could the world ever be powered from waste? Growing crops for fuel instead of food remains controversial, but biofuel technology is advancing. What\'s the future when it comes to biofuels?',
        btnText: 'Case Details',
    },
    {
        id: uuidv4(),
        image: Img1,
        category: 'Industrial',
        client: 'David Chuks',
        heading: 'Energy in Latin America: what you need to know',
        desc: 'In a lively debate, experts and students from across the region examine the big questions around lowering emissions by 2050.',
        btnText: 'Case Details',
    },

    {
        id: uuidv4(),
        image: Img2,
        category: 'Industrial',
        client: 'Rosalina D.',
        heading: 'Carbon and steel',
        desc: 'In the second of our series on heavy industry, we take a look at steel... It’s strong, it dominates every modern city, and it’s supporting developing economies. But its production generates a lot of carbon emissions. The Energy Podcast investigates.',
        btnText: 'Case Details',
    },

    {
        id: uuidv4(),
        image: Img3,
        category: 'Industrial',
        client: 'Rosalina D.',
        heading: 'Carbon and Cement',
        desc: '1.5 C … and cement… it’s all around us. But producing it generates a huge amount of CO2 every year. So, what can be done to take carbon out of the cement-making process? The Energy Podcast investigates.',
        btnText: 'Case Details',
    },

    {
        id: uuidv4(),
        image: Img4,
        category: 'Industrial',
        client: 'Rosalina D.',
        heading: 'Carbon-neutral cities: dream or reality?',
        desc: 'They cover just 3% of the earth’s land surface but they are responsible for more than 70% of all carbon emissions. What will it take to make cities around the world cleaner?',
        btnText: 'Case Details',
    },
];

export default portfolioOneData;
