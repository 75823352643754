import React, {useEffect} from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import WOW from 'wowjs'

function ServicesOneCard({ anchor, bgImg, icon, heading, btnText }) {

    
    useEffect(() => {
        let f = false;
        if(!f) {
          new WOW.WOW().init();
        }
        return () => {
          f = true
        }
      }, [])

    return (
        <div className="col-md-6 col-xl-3 col-12 wow fadeInUp" data-wow-duration={`${anchor-0.4}s`}>
            <div className="single-service-item service-1">
                <div
                    className="service-bg bg-cover"
                    style={{
                        backgroundImage: `url(${bgImg})`,
                    }}
                />
                <div className="icon">
                    <img src={icon} alt="icon" />
                </div>
                <h3>{heading}</h3>
                <Link onClick={() => window.location.replace(`/services#qs${anchor}`)}>
                    <span>{btnText}</span>
                    <BsArrowRight style={{ fontSize: '18px' }} />
                </Link>
            </div>
        </div>
    );
}

export default ServicesOneCard;
