import { v4 as uuidv4 } from 'uuid';
import Bg1 from '../../assets/img/services/Oil-and-Gas-Trading.jpg';
import Bg2 from '../../assets/img/services/2.webp';
import Bg3 from '../../assets/img/services/marine-logistics.jpg';
import Bg4 from '../../assets/img/services/vessel.jpg';
import Icon1 from '../../assets/img/icons/oilngastrading.png';
import Icon2 from '../../assets/img/icons/oil-platform.png';
import Icon3 from '../../assets/img/icons/oil-pump.png';
import Icon4 from '../../assets/img/icons/tanker.png';

const servicesOneData = [
    {
        id: uuidv4(),
        bgImg: Bg1,
        icon: Icon1,
        heading: 'OIL AND GAS TRADING',
        btnText: 'Learn More',
        anchor: '0'
    },

    {
        id: uuidv4(),
        bgImg: Bg2,
        icon: Icon2,
        heading: 'OIL & PLATFORM SERVICES',
        btnText: 'Learn More',
        anchor: '1'
    },

    {
        id: uuidv4(),
        bgImg: Bg3,
        icon: Icon3,
        heading: 'OIL DERIVATIVES',
        btnText: 'Learn More',
        anchor: '2'
    },

    {
        id: uuidv4(),
        bgImg: Bg4,
        icon: Icon4,
        heading: 'VESSEL CHATTERING',
        btnText: 'Learn More',
        anchor: '3'
    },
];

export default servicesOneData;
