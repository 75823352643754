import React from 'react';
import { Link } from 'react-router-dom';

function BlogOneItem({ timer, index, thumb, date, month, category, author, title, link }) {
    return (
        <>
            <div className="col-lg-4 col-md-6 col-12 wow flipInX" data-wow-duration={timer} id={`qs${index}`}>
                <div className="single-blog-card">
                    <div
                        className="featured-img bg-cover"
                        style={{
                            backgroundImage: `url(${thumb})`,
                        }}
                    />
                    <div className="post-content">
                        <div className="post-date">
                            <span>{date}</span>
                            {month}
                        </div>
                        <div className="post-meta" style={{marginTop: '10px'}}>
                            <Link to={link} className="post-cat">
                                {category}
                            </Link>{' '}
                            /Posted by:&nbsp;&nbsp; 
                            <Link to={link} className="post-author">
                                {author}
                            </Link>
                        </div>
                        <h3>  
                            <Link style={{cursor: 'pointer'}} onClick={() => window.location.replace(link+'#qs'+index)}>{title}</Link>
                          {/* <Link to={link+'#qs'+index}>{title}</Link> */}
                            
                        </h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogOneItem;
