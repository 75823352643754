import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function IconBoxFounder({ btnText, pageLink, timer, icon, heading, text }) {
    return (
        <div className="single-icon-box wow fadeInUp" data-wow-duration={timer}>
            <div className="icon">{icon}</div>
            <div className="content">
                <h3>{heading}</h3>
                <p>{text}</p>
                <Link to={pageLink} className="read-btn">
                    {btnText} <BsArrowRight />
                </Link>
            </div>
        </div>
    );
}

export default IconBoxFounder;
