import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const contactData = [
    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Email Address',
        text: 'Sent mail asap anytime',
        item1: 'info@florencepetroleumltd.com.ng',
        item2: 'florenc4@florencepetroleumltd.com.ng',
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Phone Number',
        text: 'Beep us at anytime',
        item1: '+2348034045673',
        item2: '+44 7341 188666',
    },

    {
        id: uuidv4(),
        icon: <FaMapMarkerAlt />,
        heading: 'Office Address',
        text: 'Sent mail asap anytime',
        item1: '4th floor, Churchgate Tower, Plot 473 Constitution Ave, Central Business District 900211,',
        item2: 'Abuja FCT, Nigeria',
         
    },
];

export default contactData;
