import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/img/services/upstream.png';
import img2 from '../../assets/img/services/middlestream.png'
import img3 from '../../assets/img/services/downstream.webp'
import img8 from '../../assets/img/portfolio/cases4.jpg';
import img5 from '../../assets/img/portfolio/project1.jpg';
import img6 from '../../assets/img/portfolio/project2.jpg';
import img7 from '../../assets/img/portfolio/project3.jpg'; 
import img4 from '../../assets/img/portfolio/project4.jpg';

const projectFilterData = [
    {
        id: uuidv4(),
        image: img1,
        name: 'Exploration',
        price: 'Production',
        heading: 'Upstream Dichotomy',
        category: 'upstream',
    },
    
    {
        id: uuidv4(),
        image: 2,
        name: 'transportation .',
        price: 'Storage',
        heading: 'Middlestream Dichotomy',
        category: 'bedroom',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'Refining',
        price: 'Marketing',
        heading: 'Downstream Dichotomy',
        category: 'floor',
    },

    {
        id: uuidv4(),
        image: img2,
        name: 'Natural gas',
        price: 'Crude Oil gas',
        heading: 'Hydrocarbon',
        category: 'home',
    },

    {
        id: uuidv4(),
        image: img4,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'upstream',
    },

    {
        id: uuidv4(),
        image: img5,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'middlestream',
    },

    {
        id: uuidv4(),
        image: img6,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'middlestream',
    },

    {
        id: uuidv4(),
        image: img6,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'downstream',
    },

    {
        id: uuidv4(),
        image: img7,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'downstream',
    },

    {
        id: uuidv4(),
        image: img7,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'middlestream',
    },

    {
        id: uuidv4(),
        image: img7,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'downstream',
    },

    {
        id: uuidv4(),
        image: img8,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'upstream',
    },
];

export default projectFilterData;
