import React from 'react';
import { FaReply } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import img1 from '../../assets/img/blog/manone.jpg';
import img2 from '../../assets/img/blog/woman2.jpg';
import img3 from '../../assets/img/blog/man2.jpg';
import ReplayComment from './ReplayComment';

function Comments() {
    return (
        <div className="comments-section-wrap pt-40">
            <div className="comments-heading">
                <h3>03 Comments</h3>
            </div>
            <ul className="comments-item-list">
                <li className="single-comment-item">
                    <div className="author-img">
                        <img src={img1} alt="" />
                    </div>
                    <div className="author-info-comment">
                        <div className="info">
                            <h5>
                                <Link to="/news-details">Mathew Adeyanju</Link>
                            </h5>
                            <span>19th May 2018</span>
                            <Link to="/news-details" className="theme-btn minimal-btn">
                                <FaReply style={{ marginTop: '-3px', marginRight: '5px' }} />
                                Reply
                            </Link>
                        </div>
                        <div className="comment-text">
                            <p>
                               Buying Lagos-based Daystar by Shell Company is a very good and welcoming idea for me due to the NPHCN epileptic power supply in the country. This, by inference, is a community development project to give back to the community.
                            </p>
                        </div>
                    </div>
                </li>
                <li className="single-comment-item">
                    <div className="author-img">
                        <img src={img2} alt="" />
                    </div>
                    <div className="author-info-comment">
                        <div className="info">
                            <h5>
                                <Link to="/news-details">Benedicta Ngozi</Link>
                            </h5>
                            <span>12th August 2022</span>
                            <Link to="/news-details" className="theme-btn minimal-btn">
                                <FaReply style={{ marginTop: '-3px', marginRight: '5px' }} />
                                Reply
                            </Link>
                        </div>
                        <div className="comment-text">
                            <p>
                                The acquisition of Indian Renewable Energy by shell is great. WE need to saveguard our ozone layer and achieving zero energy emmission will go a long way in boosting the general status and shield of our planet.
                            </p>
                        </div>
                    </div>

                    <ReplayComment
                        authorImg={img3}
                        date="29th Oct 2022"
                        name="Musa Aliyu"
                        comment="Yea Benedicta! I think you are right. Thanks so much for this insight. Our planet must be salvaged from ozone layer depletion which is otherwisely known as 'Greenhouse Effect'"
                    />
                </li>
            </ul>
        </div>
    );
}

export default Comments;
