import { v4 as uuidv4 } from 'uuid';
import img5 from '../../assets/img/portfolio/cases1.jpg';
import img1 from '../../assets/img/services/upstream.png';
import img2 from '../../assets/img/services/midstreams.jpg'
import img3 from '../../assets/img/services/downstream.webp'
import img4 from '../../assets/img/services/middlestream.png'


const projectFilterTwoData = [
    {
        id: uuidv4(),
        image: img1,
        name: 'Exploration',
        price: 'Production',
        heading: 'Upstream Dichotomy',
        category: 'upstream',
    },
    
    {
        id: uuidv4(),
        image: img2,
        name: 'transportation .',
        price: 'Storage',
        heading: 'Middlestream',
        category: 'bedroom',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'Refining',
        price: 'Marketing',
        heading: 'Downstream',
        category: 'floor',
    },

    {
        id: uuidv4(),
        image: img4,
        name: 'Natural gas',
        price: 'Oil gas',
        heading: 'Hydrocarbon',
        category: 'home',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'bedroom',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'home',
    },

    {
        id: uuidv4(),
        image: img4,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'office',
    },

    {
        id: uuidv4(),
        image: img5,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'bedroom',
    },
];

export default projectFilterTwoData;
