import { v4 as uuidv4 } from 'uuid';
import logo1 from '../../assets/img/brand/Flopay1.png';
import logo2 from '../../assets/img/brand/logo.png';
import logo3 from '../../assets/img/brand/logo-removebg-preview.png';
import logo4 from '../../assets/img/brand/ecumenical_logo.png';



const brandData = [
    {
        id: uuidv4(),
        brandLogo: logo1,
    },

    {
        id: uuidv4(),
        brandLogo: logo2,
    },

    {
        id: uuidv4(),
        brandLogo: logo3,
    },

    {
        id: uuidv4(),
        brandLogo: logo4,
    },

];

export default brandData;
